/* global I18n */

import React from 'react'
import { FormGroup, FormControl, HelpBlock } from 'react-bootstrap'
import { LabeledCheckbox } from './LabeledCheckbox'

class PriceInput extends React.Component {
  static roundToTwoDecimals(value) {
    return Math.round(value * 100) / 100
  }

  static checkForMinimumAndMaximum(amount, minimum, maximum) {
    if (amount < minimum) {
      amount = minimum
    } else if (maximum !== undefined && amount > maximum) {
      amount = maximum
    }

    return amount
  }

  static calculateFee(amount, feeOptions) {
    const { percent, flat, minimum, maximum } = feeOptions
    let fee = 0.0

    if (flat !== undefined) {
      fee += flat
    }

    if (percent !== undefined) {
      fee += amount * percent
    }

    fee = PriceInput.checkForMinimumAndMaximum(fee, minimum, maximum)

    return fee
  }

  static calculateCharge(amount, feeOptions) {
    let { percent, flat, minimum, maximum } = feeOptions

    percent = percent || 0.0
    flat = flat || 0.0
    minimum = minimum || 0.0

    let totalFee = (amount * percent + flat) / (1 - percent)
    totalFee = PriceInput.checkForMinimumAndMaximum(totalFee, minimum, maximum)
    let charge = amount + totalFee

    return charge
  }

  static feeDescription(feeOptions) {
    const result = []

    if (feeOptions.percent) {
      result.push((feeOptions.percent * 100).toFixed(2) + ' %')
    }

    if (feeOptions.flat) {
      result.push(feeOptions.flat.toFixed(2) + ' kr.')
    }

    return result.join(' + ')
  }

  constructor(props) {
    super(props)

    const currentValue = (parseFloat(props.value) || 0.0).toFixed(2)

    this.state = {
      value: currentValue,
      included: true,
      activated: currentValue > 0.0
    }
  }

  feeCalculations(amount) {
    const { minimumCharge } = this.props
    const { vatRate, paymentFee, applicationFee } = this.props
    const { included } = this.state
    let vat = 0.0
    let charge

    if (included) {
      charge = amount
    } else {
      charge = PriceInput.calculateCharge(amount, applicationFee)
    }
    charge = Math.max(charge, minimumCharge)

    let applicationFeeAmount = PriceInput.calculateFee(charge, applicationFee)
    const paymentFeeAmount = PriceInput.calculateFee(charge, paymentFee)

    if (vatRate !== undefined) {
      vat = applicationFeeAmount * vatRate
      applicationFeeAmount += vat
    }

    const earnings = charge - applicationFeeAmount
    return ({
      charge: PriceInput.roundToTwoDecimals(charge),
      vatAmount: vat,
      earnings: PriceInput.roundToTwoDecimals(earnings),
      paymentFeeAmount,
      applicationFeeAmount
    })
  }

  feeDescriptions() {
    const { vatRate, paymentFee, applicationFee } = this.props

    return ({
      vatDescription: PriceInput.feeDescription({ percent: vatRate }),
      paymentFeeDescription: PriceInput.feeDescription(paymentFee),
      applicationFeeDescription: PriceInput.feeDescription(applicationFee)
    })
  }

  handleChangeAmount(event) {
    this.setState({ value: event.target.value })
  }

  handleBlurAmount(event) {
    this.sanitizeValue()
  }

  sanitizeValue() {
    const { included, value } = this.state
    let amount = parseFloat(value) || 0.0
    const { charge, earnings } = this.feeCalculations(amount)

    if (included && amount < charge) {
      amount = charge
    } else if (!included && amount < earnings) {
      amount = earnings
    }

    this.setState({ value: amount.toFixed(2) })
  }

  handleChangeIncluded(event) {
    this.setState({ included: event.target.checked })
  }

  handleChangeActivated(event) {
    this.setState({ activated: event.target.checked })
  }

  render() {
    const { value, included, activated } = this.state
    const { inputProps, paymentProviderLink } = this.props
    const amount = parseFloat(value)
    const {
      charge,
      applicationFeeAmount,
      earnings,
      paymentFeeAmount
    } = this.feeCalculations(amount)
    const { applicationFeeDescription, paymentFeeDescription } = this.feeDescriptions()
    const helpMessage = {
      __html: I18n.t('components.price_input.total_fee_html', {
        price: charge.toFixed(2),
        application_fee: applicationFeeAmount.toFixed(2),
        application_fee_description: applicationFeeDescription,
        earnings: earnings.toFixed(2),
        payment_fee: paymentFeeAmount.toFixed(2),
        payment_fee_description: paymentFeeDescription,
        payment_provider_link: paymentProviderLink
      })
    }

    let formValue
    if (!activated) {
      formValue = 0.0
    } else if (included) {
      formValue = value
    } else {
      formValue = charge
    }

    return (
      <FormGroup>
        <div className="checkbox">
          <LabeledCheckbox
            id="payment_fee_activated_checkbox"
            key="activated"
            checked={activated}
            onChange={e => this.handleChangeActivated(e)}
            labelText={I18n.t('components.price_input.has_price')}
          />
        </div>

        <input
          type="hidden"
          name={inputProps.name}
          value={formValue}
          key="formValue"
        />

        {activated &&
          <div>
            <FormControl
              {...inputProps}
              id="payment_fee_price"
              key="formInput"
              name={null}
              defaultValue={value}
              onChange={e => this.handleChangeAmount(e)}
              onBlur={e => this.handleBlurAmount(e)}
            />

            <div className="checkbox">
              <LabeledCheckbox
                id="payment_fee_included_checkbox"
                key="included"
                checked={included}
                onChange={e => this.handleChangeIncluded(e)}
                labelText={I18n.t('components.price_input.include_fees')}
              />
            </div>

            <HelpBlock dangerouslySetInnerHTML={helpMessage} />
          </div>
        }
      </FormGroup>
    )
  }
}

PriceInput.defaultProps = {
  value: 0.0,
  inputProps: {},
  vatRate: 0.0,
  minimumCharge: 0.0
}

PriceInput.propTypes = {
  value: PropTypes.string,
  inputProps: PropTypes.object,
  vatRate: PropTypes.number,
  paymentProviderLink: PropTypes.string.isRequired,
  minimumCharge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  applicationFee: PropTypes.shape({
    minimum: PropTypes.number,
    maximum: PropTypes.number,
    percent: PropTypes.number,
    flat: PropTypes.number
  }),
  paymentFee: PropTypes.shape({
    minimum: PropTypes.number,
    maximum: PropTypes.number,
    percent: PropTypes.number,
    flat: PropTypes.number
  })
}

export default PriceInput
