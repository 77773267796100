import React from 'react';

const defaultProps = {
    id: null,
    defaultChecked: false,
    checked: false,
    checkboxClass: null,
    onChange: null,
    labelText: null,
}

LabeledCheckbox.propTypes = {
    id: PropTypes.string,
    defaultChecked: PropTypes.bool,
    checkboxClass: PropTypes.string,
    onChange: PropTypes.func,
    labelText: PropTypes.string,
}

function LabeledCheckbox(props) {
    const { id, checked, checkboxClass, onChange, labelText } = { ...defaultProps, ...props };

    return <label htmlFor={id} className={`my-checkbox ${checkboxClass}`}>
        <div>
            <input
                type="checkbox"
                checked={checked}
                id={id}
                onChange={onChange} />
            <span className="checkmark"></span>
        </div>
        {labelText}
    </label >

}

export { LabeledCheckbox };
