// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'react-hot-loader/patch'
import 'font-awesome/css/font-awesome.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'

import PriceInput from '../application/components/PriceInput'
import MapInput from '../application/components/MapInput'
import Select from '../application/components/Select'
import ClipboardWidget from '../application/components/ClipboardWidget'
import ShiftReportForm from '../ShiftReportForm'

const reactApps = {
  Select,
  MapInput,
  PriceInput,
  ShiftReportForm,
  ClipboardWidget,
}

// Render component with data
const getReactApps = () => (
  Array.from(document.querySelectorAll('[data-react-app]'))
)

const mountReactComponent = (component, element, options) => {
  ReactDOM.render(
    React.createElement(
      AppContainer,
      {},
      React.createElement(component, options)
    ),
    element
  )
}

const mountReactApps = () => {
  getReactApps().forEach((element) => {
    const data = element.dataset
    const currentReactApp = reactApps[data.reactApp]

    if (currentReactApp !== undefined) {
      mountReactComponent(currentReactApp, element, JSON.parse(data.options))
    }
  })
}

const unMountReactApps = () => {
  getReactApps().forEach((element) => {
    ReactDOM.unmountComponentAtNode(element)
  })
}

if (module.hot) {
  module.hot.accept(
    [
      '../application/components/Select',
      '../application/components/MapInput',
      '../application/components/PriceInput',
      '../ShiftReportForm',
      '../application/components/ClipboardWidget',
    ],
    (updatedDependencies) => {
      updatedDependencies.forEach((updatedDependency) => {
        const regex = /\/([^\/]+)(?:\/index)?\.jsx?$/
        const appName = updatedDependency.match(regex)[1]
        const component = reactApps[appName]
        const domElement = document.querySelector(`[data-react-app="${appName}"]`)
        const data = domElement.dataset

        mountReactComponent(component, domElement, JSON.parse(data.options))
      })
    }
  )
}

document.addEventListener('DOMContentLoaded', mountReactApps)
document.addEventListener('DOMContentChanged', mountReactApps)
document.addEventListener('turbolinks:load', mountReactApps)
document.addEventListener('turbolinks:before-cache', unMountReactApps)
